<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            :headers="headers"
            :items="dataForm.users"
            :search="search"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="4" class="w-50">
                  <div class="total-heading total-heading-left">
                    <h1>Total User: {{ dataForm?.total ? dataForm.total : 0 }}</h1>
                  </div>
                  <div class="user-roles-information">
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>{{ dataForm?.count?.super_admin ? dataForm.count.super_admin : 0 }}</p>
                      </div>
                      <div class="user-role-name">
                        <span>Superadmin</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>{{ dataForm?.count?.admin ? dataForm.count.admin : 0 }}</p>
                      </div>
                      <div class="user-role-name">
                        <span>Admin</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>{{ dataForm?.count?.operator ? dataForm.count.operator : 0 }}</p>
                      </div>
                      <div class="user-role-name">
                        <span>Operator</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>{{ dataForm?.count?.site_engineer ? dataForm.count.site_engineer : 0 }}</p>
                      </div>
                      <div class="user-role-name">
                        <span>Site Engineer</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>{{ dataForm?.count?.home_user ? dataForm.count.home_user : 0 }}</p>
                      </div>
                      <div class="user-role-name">
                        <span>Home User</span>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="5" class="w-50">
                  <!-- <div class="total-heading">
                    <h1>User Management</h1>
                  </div> -->
                  <div class="switch-component switch-custom">
                    <button class="dialog-default-button btn_UserManagement data-table-add-size dialog-bg-grey-button switch-btn add-user-btn button-hover " @click="openDialogAddUser">
                      Add New User
                    </button>
                    <v-text-field
                      v-model="search"
                      placeholder="Search..."
                      class="search-site"
                      prepend-inner-icon="fas fa-search"
                      rounded
                      outlined
                      filled
                    ></v-text-field>
                    <v-btn
                      class="switch-btn button-hover"
                      icon
                      @click="openDialogFilterUser"
                      title="Filter User"
                    >
                      <img src="@/assets/tables/filter-icon.svg" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.assign_sites`]="{ item }">
              <v-tooltip v-if="getAssignedSitesName(item.assign_sites)?.length > 40" bottom :max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                  >
                    {{ showMaxValue(40, item.assign_sites, true) }}
                  </span>
                </template>
                <span>{{ getAssignedSitesName(item.assign_sites) }}</span>
              </v-tooltip>
              <span v-else>{{ getAssignedSitesName(item.assign_sites) }}</span>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip v-if="item.email?.length > 32" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                  >
                    {{ showMaxValue(32, item.email) }}
                  </span>
                </template>
                <span>{{ item.email }}</span>
              </v-tooltip>
              <span v-else>{{ item.email }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="!item.deleted_at">
                <div class="d-flex justify-content-center">
                  <v-btn
                    :ripple="false"
                    icon
                    v-if="item.status == 'locked'"
                    title="Unlock User"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/locked-icon.svg"
                      @click="
                        openConfirmDialog(
                          item,
                          updateStatus,
                          'Unlock User',
                          'You are about to unlock a user from the current system. Are you sure you want to continue this action?'
                        )
                      " />
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    v-if="item.status == 'unlocked'"
                    title="Lock User"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/unlocked-icon.svg"
                      @click="
                        openConfirmDialog(
                          item,
                          updateStatus,
                          'Lock User',
                          'You are about to lock a user from the current system. Are you sure you want to continue this action?'
                        )
                      " />
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    title="Edit User"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_edit.svg" 
                      @click="openDialogEdit(item)" />
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    title="Delete User"
                    @click="openConfirmDialog(item, deleteUser, 'Delete User', 'You are about to delete an user from the current system. This user will no longer be registered and controlled.')"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_delete.svg" 
                    />
                  </v-btn>
                </div>
              </template>
            </template>
          </v-data-table>
          <div class="data-table-paging">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddUser"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="786"
      >
        <v-card>
          <v-card-title>Add New User</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="addForm"
              @submit.prevent="submitAdd"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Username</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="userName"
                      :rules="userNameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Username"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Email</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="userEmail"
                      :rules="userEmailRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Email Address"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>User Group</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="userGroupsList"
                      v-model="userGroup"
                      :rules="userGroupRules"
                      item-text="name"
                      item-value="id"
                      :class="`${userGroup ? 'custom-hidden-input' : ''}`"
                      class="custom-select__selections"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select User Group"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Assign Site(s)</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="assignSitesList"
                      v-model="assignSite"
                      :rules="userAssignSiteAddRules"
                      item-text="name"
                      item-value="id"
                      class="custom-select__selections custom-hidden-input icon_select_color"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_multi_menu_position custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      :prefix="`${!assignSite.length ? 'Select Site ID' : ''}`"
                      multiple
                      chips
                    >
                      <template v-slot:prepend-item v-if="assignSitesList.length">
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleSelectAll"
                        >
                          <v-list-item-action>
                            <a
                              class="select_all_icon"
                              v-html="
                                renderIcon(
                                  selectedAllItems,
                                  'i',
                                  'fas fa-check-square',
                                  'far fa-square'
                                )
                              "
                            >
                            </a>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all/Unselect all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 2">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 2"
                          class="text-grey text-caption align-self-center"
                        >
                          (+{{ assignSite.length - 2 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Password</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :class="`${userPassword ? 'custom-font-password' : ''}`"
                      class="user-password"
                      style="margin-right: 8px;"
                      v-model="userPassword"
                      :rules="userPasswordAddRules"
                      required
                      :type="showConfirmNewPass ? 'text' : 'password'"
                      autocomplete="off"
                      placeholder="Enter Password"
                      filled
                    >
                      <template v-slot:append>
                        <a
                          style="margin-top: 4px"
                          @click="showConfirmNewPass = !showConfirmNewPass"
                          v-html="
                            renderIcon(
                              showConfirmNewPass,
                              'i',
                              'fas fa-eye',
                              'fas fa-eye-slash'
                            )
                          "
                        >
                        </a>
                      </template>
                    </v-text-field>
                    <div v-if="userPassword && isValid" class="message-send-password">
                      <p class="message-content">Password will be sent to registered email address</p>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <button
                      class="dialog-default-button dialog-bg-grey-button"
                      style="max-height: 48px;"
                      type="button"
                      :ripple="false"
                      @click="autoGenerate"
                    >
                      Auto Generate
                    </button>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogAddUser"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditUser"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="786"
      >
        <v-card>
          <v-card-title>Edit User</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="editForm"
              @submit.prevent="submitEdit"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters class="pt-2" style="pointer-events: none;">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Username</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editUsername"
                      :rules="userNameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Username"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Email</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editUserEmail"
                      :rules="userEmailRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Email Address"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>User Group</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="userGroupsList"
                      v-model="editUserGroup"
                      :rules="userGroupRules"
                      item-text="name"
                      item-value="id"
                      :class="`${editUserGroup ? 'custom-hidden-input' : ''}`"
                      class="custom-select__selections"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select User Group"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Assign Site(s)</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="assignSitesList"
                      v-model="editAssignSite"
                      :rules="userAssignSiteEditRules"
                      item-text="name"
                      item-value="id"
                      class="custom-select__selections custom-hidden-input icon_select_color"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_multi_menu_position custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      :prefix="`${!editAssignSite.length ? 'Select Site ID' : ''}`"
                      multiple
                      chips
                    >
                      <template v-slot:prepend-item v-if="assignSitesList.length">
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleSelectAllEdit"
                        >
                          <v-list-item-action>
                            <a
                              class="select_all_icon"
                              v-html="
                                renderIcon(
                                  selectedAllItemsEdit,
                                  'i',
                                  'fas fa-check-square',
                                  'far fa-square'
                                )
                              "
                            >
                            </a>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all/Unselect all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 2">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 2"
                          class="text-grey text-caption align-self-center"
                        >
                          (+{{ editAssignSite.length - 2 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Password</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :class="`${editUserPassword ? 'custom-font-password' : ''}`"
                      class="user-password"
                      style="margin-right: 8px;"
                      v-model="editUserPassword"
                      :rules="userPasswordEditRules"
                      required
                      :type="showConfirmNewPass ? 'text' : 'password'"
                      autocomplete="off"
                      placeholder="Enter Password"
                      filled
                    >
                      <template v-slot:append>
                        <a
                          style="margin-top: 4px"
                          @click="showConfirmNewPass = !showConfirmNewPass"
                          v-html="
                            renderIcon(
                              showConfirmNewPass,
                              'i',
                              'fas fa-eye',
                              'fas fa-eye-slash'
                            )
                          "
                        >
                        </a>
                      </template>
                    </v-text-field>
                    <div v-if="editUserPassword && isValid" class="message-send-password">
                      <p class="message-content">Password will be sent to registered email address</p>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <button
                      class="dialog-default-button dialog-bg-grey-button"
                      style="max-height: 48px;"
                      type="button"
                      :ripple="false"
                      @click="autoGenerate('edit')"
                    >
                      Auto Generate
                    </button>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogEdit"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFilterUser"
        persistent
        content-class="custom-content-modals"
        max-width="700"
      >
        <v-card>
          <v-card-title class="v-card__title-filter">Filter</v-card-title>
          <v-card-text class="custom-asset-card v-card__text-filter">
            <v-form
              ref="filterUserForm"
              @submit.prevent="submitFilter"
              autocomplete="off"
            >
              <v-row no-gutters>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">User Group</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedUserGroups" class="cursor-pointer" type="checkbox" id="group-super-admin" name="group-super-admin" value="super_admin" />
                        <label for="group-super-admin">Super Admin</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedUserGroups" class="cursor-pointer" type="checkbox" id="group-admin" name="group-admin" value="admin" />
                        <label for="group-admin">Admin</label>
                      </div>
                    </v-col>

                    <v-col cols="4" style="visibility: hidden;">
                      <div class="option-item">
                        <input class="cursor-pointer" type="checkbox" id="group-hidden" />
                        <label for="group-hidden">Hidden</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedUserGroups" class="cursor-pointer" type="checkbox" id="group-operator" name="group-operator" value="operator" />
                        <label for="group-operator">Operator</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedUserGroups" class="cursor-pointer" type="checkbox" id="group-site-engineer" name="group-site-engineer" value="site_engineer" />
                        <label for="group-site-engineer">Site Engineer</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedUserGroups" class="cursor-pointer" type="checkbox" id="group-home-user" name="group-home-user" value="home_user" />
                        <label for="group-home-user">Home User</label>
                      </div>
                    </v-col>          
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Lock Status</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedLockStatuses" class="cursor-pointer" type="checkbox" id="locked-status" name="locked-status" value="locked" />
                        <label for="locked-status">Locked</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedLockStatuses" class="cursor-pointer" type="checkbox" id="unlocked-status" name="unlocked-status" value="unlocked" />
                        <label for="unlocked-status">Unlocked</label>
                      </div>
                    </v-col>

                    <v-col cols="4" style="visibility: hidden;">
                      <div class="option-item">
                        <input class="cursor-pointer" type="checkbox" id="group-hidden" />
                        <label for="group-hidden">Hidden</label>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Commissioned Date</p>
                  <v-row no-gutters class="checkboxes-row commissioned-date-row">
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p>From</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-menu
                        ref="commissionedDateMenu"
                        v-model="commissionedDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="332"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="commissionedDateTextFrom"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            append-icon="fas fa-caret-down"
                            class="date-picker-text-field"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          flat
                          v-model="commissionedDateRange"
                          no-title
                          range
                          width="auto"
                          first-day-of-week="0"
                          type="date"
                          :dark="true"
                          class="customize_commissioned_date_picker"
                          @input="closeCommissionedDateMenu"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p style="margin-left: 13px;">To</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-text-field
                        v-model="commissionedDateTextTo"
                        readonly
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        @click="openCommissionedDateMenu"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters class="filter-buttons-row">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogFilterUser"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="resetDialogFilterUser"
                  >
                    RESET
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    APPLY
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(userItem)"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { userService } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      search: "",
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "No", value: "id", align: 'center', sortable: false },
        { text: "Username", value: "username", align: 'center', sortable: false },
        { text: "User Group", value: "user_group_name", align: 'center', sortable: false },
        { text: "Assigned Sites", value: "assign_sites", align: 'center', sortable: false },
        { text: "Email Address", value: "email", align: 'center', sortable: false },
        { text: "Registered Date", value: "created_at", align: 'center', sortable: false },
        { text: "Last Login", value: "last_login", align: 'center', sortable: false },
        { text: "Action", value: "actions", sortable: false, align: 'center', filterable: false },
      ],
      dialogAddUser: false,
      dialogEditUser: false,
      dialogFilterUser: false,
      confirmDialog: false,
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      userItem: {},
      dataForm: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      userName: "",
      userPassword: "",
      userGroup: "",
      assignSite: [],
      userEmail: "",
      deviceModel: "",
      ratedCapacity: "",
      userGroupsList: [],
      assignSitesList: [],
      commissionedDateMenu: false,
      selectedUserGroups: [],
      selectedLockStatuses: [],
      commissionedDateRange: [],
      showConfirmNewPass: false,
      editUsername: "",
      editUserPassword: "",
      editUserGroup: "",
      editAssignSite: [],
      editUserEmail: "",
      isValid: false,
      userNameRules: [
        (v) => !!v || "User Name is required",
        (v) => (v && v.length >= 3 && v.length <= 20) || "User Name must be between 3 and 20 characters",
        (v) => /^[a-zA-Z0-9]+$/.test(v) || "User Name cannot contain special characters",
      ],
      userGroupRules: [
        (v) =>!!v || "User Group is required",
      ],
      userEmailRules: [
        (v) =>!!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
        (v) => (v && v.length <= 80) || "Email must be at most 80 characters",
      ],
    };
  },
  async created() {
    this.getListUsers();
    this.getListSitesAndRoles();
  },
  computed: {
    commissionedDateTextFrom () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 0){
        return `${this.commissionedDateRange[0] ? moment(this.commissionedDateRange[0]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    commissionedDateTextTo () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 1){
        return `${this.commissionedDateRange[1] ? moment(this.commissionedDateRange[1]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    selectedAllItems () {
      return this.assignSite.length === this.assignSitesList.length;
    },
    selectedAllItemsEdit () {
      return this.editAssignSite.length === this.assignSitesList.length;
    },
    userAssignSiteAddRules () {
      return [
        (v) => !!v.length || "Assign Site is required",
        (v) => v.length > 5 && this.getUserGroupTypeById(this.userGroup) === "home_user" ? "Home User is assigned a maximum of 5 sites" : true,
      ];
    },
    userAssignSiteEditRules () {
      return [
        (v) => !!v.length || "Assign Site is required",
        (v) => v.length > 5 && this.getUserGroupTypeById(this.editUserGroup) === "home_user" ? "Home User is assigned a maximum of 5 sites" : true,
      ];
    },
    userPasswordAddRules () {
      return [
        (v) => {
          if (!v) {
            this.isValid = false;
            return "Password is required";
          }
          if (v.length < 8) {
            this.isValid = false;
            return "Password must be at least 8 characters";
          }
          if (!/(?=.*[A-Z])/.test(v)) {
            this.isValid = false;
            return "Password must contain at least 1 uppercase letter";
          }
          if (!/(?=.*[a-z])/.test(v)) {
            this.isValid = false;
            return "Password must contain at least 1 lowercase letter";
          }
          if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) {
            this.isValid = false;
            return "Password must contain at least 1 special character";
          }
          if (!/(?=.*\d)/.test(v)) {
            this.isValid = false;
            return "Password must contain at least 1 number";
          }
          this.isValid = true;
          return true;
        }
      ]
    },
    userPasswordEditRules () {
      return [
        (v) => {
          if (v) {
            if (v.length < 8) {
              this.isValid = false;
              return "Password must be at least 8 characters";
            }
            if (!/(?=.*[A-Z])/.test(v)) {
              this.isValid = false;
              return "Password must contain at least 1 uppercase letter";
            }
            if (!/(?=.*[a-z])/.test(v)) {
              this.isValid = false;
              return "Password must contain at least 1 lowercase letter";
            }
            if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) {
              this.isValid = false;
              return "Password must contain at least 1 special character";
            }
            if (!/(?=.*\d)/.test(v)) {
              this.isValid = false;
              return "Password must contain at least 1 number";
            }
            this.isValid = true;
            return true;
          }
          this.isValid = true;
          return true;
        }
      ]
    },
  },
  methods: {
    getListUsers(params) {
      userService.getListUsers(params).then((res) => {
        if(res?.data?.data) {
          const data = res.data.data;
          if (data?.users?.length) {
            data.users.forEach(user => {
              user.created_at = this.MOMENT(user?.created_at, 'DD-MM-YYYY') || "−−";
              user.last_login = this.formatDataTableDate(user?.last_login) || "−−";
            });
          }
          this.dataForm = {...data};
        }
      });
    },
    deleteUser(item) {
      if (item?.id) {
        userService
          .deleteUser(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.apiMessage = "User has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListUsers();
          })
          .catch((err) => {
            let message = "User cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    async submitAdd() {
      if (this.$refs.addForm.validate()) {
        const data = this.prepareData(
          this.userName,
          this.userGroup,
          this.assignSite,
          this.userEmail,
          this.userPassword,
        );
        await userService
          .storeUser(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListUsers();
            this.closeDialogAddUser();
            this.apiMessage = "User has been successfully created";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "User cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertUser() {
      this.$refs.addForm.reset();
    },
    openDialogAddUser() {
      this.dialogAddUser = true;
    },
    openDialogFilterUser() {
      this.dialogFilterUser = true;
    },
    closeDialogAddUser() {
      this.revertUser();
      this.dialogAddUser = false;
      this.showConfirmNewPass = false;
    },
    resetDialogFilterUser() {
      this.selectedUserGroups = [];
      this.selectedLockStatuses = [];
      this.commissionedDateRange = [];
    },
    closeDialogFilterUser() {
      this.dialogFilterUser = false;
    },
    openCommissionedDateMenu() {
      return this.commissionedDateMenu = !this.commissionedDateMenu;
    },
    openConfirmDialog(item, action, heading, message) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.userItem = Object.assign({}, this.userItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
    },
    closeMessage() {
      this.messageDialog = false;
    },
    closeCommissionedDateMenu() {
      if(this.commissionedDateRange && this.commissionedDateRange[0] && this.commissionedDateRange[1]){
        this.commissionedDateMenu = false;
      }else{
        this.commissionedDateMenu = true;
      }
      return this.commissionedDateMenu;
    },
    async submitFilter() {
      if(!(this.selectedUserGroups?.length || this.selectedLockStatuses?.length || this.commissionedDateRange?.length)) {
        await this.getListUsers();
      } else {
        let prefix = "?";
        let params = "";
        if(this.selectedUserGroups?.length) {
          params += `${prefix}user_groups=` + JSON.stringify(this.selectedUserGroups);
          prefix = "&";
        }
        if(this.selectedLockStatuses?.length) {
          params += `${prefix}status=` + JSON.stringify(this.selectedLockStatuses);
          prefix = "&";
        }
        if(this.commissionedDateRange?.length === 2) {
          params += `${prefix}start_date=${this.commissionedDateRange[0]}&end_date=${this.commissionedDateRange[1]}`;
        }
        await this.getListUsers(params);
      }
      this.closeDialogFilterUser();
    },
    prepareData(user_name, user_group, assign_site, email, password) {
      const data = {
        user_group_id: user_group,
        assign_site: assign_site,
      };
      if(user_name){
        data.username = user_name;
      }
      if(email){
        data.email = email;
      }
      if (password) {
        data.password = password;
      }
      return data;
    },
    getListSitesAndRoles() {
      return userService
        .getListSitesAndRoles()
        .then((res) => {
          if (res?.data?.data) {
            const dataUserGroup = res.data.data.user_groups ? res.data.data.user_groups : [];
            const dataAssignSite = res.data.data.sites ? res.data.data.sites : [];
            this.userGroupsList = [...dataUserGroup];
            this.assignSitesList = [...dataAssignSite];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    generatePassword(length, includeUppercase, includeLowercase, includeNumbers, includeSpecialChars) {
      const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
      const numberChars = '0123456789';
      const specialChars = '!@#$%^&*-=_+';
      let allChars = '';
      if (includeUppercase) allChars += uppercaseChars;
      if (includeLowercase) allChars += lowercaseChars;
      if (includeNumbers) allChars += numberChars;
      if (includeSpecialChars) allChars += specialChars;
      if (allChars.length === 0) {
        return null;
      }
      let password = '';
      let hasUppercase = false;
      let hasLowercase = false;
      let hasNumber = false;
      let hasSpecialChar = false;
      while (password.length < length || !hasUppercase || !hasLowercase || !hasNumber || !hasSpecialChar) {
          password = '';
          hasUppercase = false;
          hasLowercase = false;
          hasNumber = false;
          hasSpecialChar = false;
          for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            const char = allChars.charAt(randomIndex);
            password += char;
            if (uppercaseChars.includes(char)) hasUppercase = true;
            if (lowercaseChars.includes(char)) hasLowercase = true;
            if (numberChars.includes(char)) hasNumber = true;
            if (specialChars.includes(char)) hasSpecialChar = true;
          }
      }
      return password;
    },
    autoGenerate(type = "create") {
      if(type === "edit") {
        this.editUserPassword = this.generatePassword(10, true, true, true, true);
      } else {
        this.userPassword = this.generatePassword(10, true, true, true, true);
      }
    },
    toggleSelectAll () {
      this.$nextTick(() => {
        if (this.selectedAllItems) {
          this.assignSite = [];
        } else {
          this.assignSite = this.assignSitesList.map(item => item.id);
        }
      })
    },
    toggleSelectAllEdit () {
      this.$nextTick(() => {
        if (this.selectedAllItemsEdit) {
          this.editAssignSite = [];
        } else {
          this.editAssignSite = this.assignSitesList.map(item => item.id);
        }
      })
    },
    getAssignedSitesName(arr) {
      let assignedSites = arr?.length ? arr.map(item => item?.sites?.name).filter(name => name !== null && name !== undefined).join(', ') : '';
      return assignedSites;
    },
    setUserInfo(data) {
      if (data) {
        this.editUsername = data.username || "";
        this.editUserEmail = data.email || "";
        this.editUserGroup = data.user_group_id || "";
        this.editAssignSite = data.assign_sites.map(site => site.id) || [];
        this.userInfo = data;
      } else {
        this.editUsername = "";
        this.editUserEmail = "";
        this.editUserGroup = "";
        this.editAssignSite = [];
        this.userInfo = null;
      }
    },
    openDialogEdit(item) {
      if (item?.id) {
        userService
          .getUser(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setUserInfo(res.data.data);
              this.dialogEditUser = true;
            }
          })
          .catch();
      }
    },
    closeDialogEdit() {
      this.setUserInfo(null);
      this.revertUserEdit();
      this.dialogEditUser = false;
      this.showConfirmNewPass = false;
    },
    submitEdit() {
      if (this.$refs.editForm.validate()) {
        const data = this.prepareData(
          null,
          this.editUserGroup,
          this.editAssignSite,
          this.editUserEmail,
          this.editUserPassword
        );

        userService
          .updateUser(this.userInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.apiMessage = "User's information has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListUsers();
            this.closeDialogEdit();
          })
          .catch((err) => {
            let message = "User's information cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertUserEdit() {
      this.$refs.editForm.reset();
    },
    updateStatus(item) {
      if (item?.id) {
        const data = {
          status: item.status === "locked" ? "unlocked" : "locked",
        };

        userService
          .UpdateUserStatus(item.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.closeConfirmDialog();
            this.apiMessage = `User has been successfully ${res.data?.data?.status === "locked" ? "locked" : "unlocked"}`;
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListUsers();
          })
          .catch((err) => {
            let message = "User's status cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    getUserGroupTypeById(userGroupId) {
      let selectedGroup = this.userGroupsList.find(group => group.id === userGroupId);
      return selectedGroup ? selectedGroup.type : null;
    },
    showMaxValue(max_length, key_value, is_assign_sites = false) {
      const maxLength = max_length;
      let keyValue = key_value;
      if (is_assign_sites) {
        keyValue = this.getAssignedSitesName(key_value);
      }
      return keyValue.length > maxLength ? keyValue.slice(0, maxLength) + '...' : keyValue;
    },
  },
};
</script>
